var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "navi-item" },
    [
      _c(
        _vm.relatedUrl ? "a" : "div",
        {
          tag: "component",
          staticClass: "navi-link rounded",
          class: _vm.relatedUrl ? "" : "no-hover",
          attrs: {
            href: _vm.relatedUrl ? _vm.relatedUrl : "",
            target: _vm.isAbsoluteUrl ? "_blank" : ""
          },
          on: { click: _vm.linkClickHandler }
        },
        [
          _c(
            "div",
            {
              staticClass:
                "symbol symbol-50 mr-3 position-relative overflow-hidden"
            },
            [
              _c(
                "div",
                {
                  staticClass: "symbol-label",
                  class: _vm.imgUrl ? "img-bg" : "",
                  style: _vm.imgBackground
                },
                [
                  !_vm.imgUrl
                    ? _c("i", { staticClass: "icon-lg", class: _vm.iconClass })
                    : _vm._e()
                ]
              )
            ]
          ),
          _c(
            "div",
            {
              staticClass: "navi-text",
              class: _vm.seen ? "" : "font-weight-boldest"
            },
            [
              _c("div", { staticClass: "font-size-lg" }, [
                _vm._v(
                  " " +
                    _vm._s(_vm.title ? _vm.title : "Introducir Título") +
                    " "
                )
              ]),
              _c("div", { staticClass: "text-muted" }, [
                _vm._v(" " + _vm._s(_vm.description) + " ")
              ]),
              _c(
                "div",
                { staticClass: "text-muted font-size-xs font-italic" },
                [
                  _c("span", { staticStyle: { color: "#FF3670" } }, [
                    _vm._v(_vm._s(_vm.$t("NOTIFICATIONS.NEW")))
                  ]),
                  _vm._v(" " + _vm._s(_vm.$t("NOTIFICATIONS.NOW")) + " ")
                ]
              )
            ]
          ),
          _c("div", { staticClass: "symbol symbol-20 ml-2" }, [
            _vm.relatedUrl
              ? _c("div", { staticClass: "symbol-label bg-white" }, [
                  _c("i", {
                    staticClass:
                      "icon-md flaticon3-link-1 color-muted link-icon"
                  })
                ])
              : _vm._e()
          ])
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }