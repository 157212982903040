<style lang="scss" scoped>
.navi-link {
  &.no-hover {
    &:hover {
      color: inherit;
      .navi-text {
        color: inherit;
      }
    }
  }
  &:hover {
    .link-icon {
        color: #FF3670;
      }
  }
  // .unseen-dot {
  //   position: absolute;
  //   top: -2px;
  //   right: -2px;
  //   width: 10px;
  //   height: 10px;
  //   background-color: #d35353;
  //   border-radius: 50%;
  // }
  .img-bg {
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
  }
  .unseen-border {
    border-left: .31rem solid #FF3670;
    border-radius: .42rem;
  }
  .absolute-overlay {
    position: absolute;
    top: 0px;
    right: 0px;
    left: 0px;
    bottom: 0px;
  }
}
</style>

<template>
  <!--begin::Item-->
  <div class="navi-item">
    <component
      :is="relatedUrl ? 'a' : 'div'"
      :href="relatedUrl ? relatedUrl : ''"
      class="navi-link rounded"
      :class="relatedUrl ? '' : 'no-hover'"
      :target="isAbsoluteUrl ? '_blank' : ''"
      @click="linkClickHandler"
    >
      <div class="symbol symbol-50 mr-3 position-relative overflow-hidden">
        <!-- <div v-if="!seen" class="unseen-border absolute-overlay"></div> -->
        <!-- <div v-if="showUnseenDot && !seen" class="unseen-dot"></div> -->
        <div class="symbol-label" :class="imgUrl ? 'img-bg' : ''" :style="imgBackground">
          <i v-if="!imgUrl" class="icon-lg" v-bind:class="iconClass" />
        </div>
      </div>
      <div class="navi-text" :class="seen ? '' : 'font-weight-boldest'">
        <div class="font-size-lg">
          {{ title ? title : 'Introducir Título' }}
        </div>
        <div class="text-muted">
          {{ description }}
        </div>
        <div class="text-muted font-size-xs font-italic">
          <span style="color: #FF3670">{{ $t("NOTIFICATIONS.NEW") }}</span> {{ $t("NOTIFICATIONS.NOW") }}
        </div>
      </div>
      <div class="symbol symbol-20 ml-2">
        <div v-if="relatedUrl" class="symbol-label bg-white">
          <i class="icon-md flaticon3-link-1 color-muted link-icon" />
        </div>
      </div>
    </component>
  </div>
  <!--end::Item-->
</template>

<script>
export default {
  name: "NotificationsItemPreview",
  props: {
    // showUnseenDot: {
    //   type: Boolean,
    //   default: false,
    // },
    iconClass: {
      type: String,
      default: "flaticon-bell text-primary",
    },
    title: {
      type: String,
      default: "Notification Title",
    },
    description: {
      type: String,
      default: "Some additional specific info.",
    },
    seen: {
      type: Boolean,
      default: true,
    },
    createdAt: {
      type: String,
      default: new Date().toISOString(),
    },
    imgUrl: {
      type: String,
      default: null,
    },
    relatedUrl: {
      type: String,
      default: null,
    },
  },
  data() {
    return {};
  },
  mounted() {},
  methods: {
    linkClickHandler() {
      if (this.relatedUrl && !this.isAbsoluteUrl) {
        this.$parent.hidePanel();
      }
    }
  },
  computed: {
    isAbsoluteUrl() {
      if (!this.relatedUrl) {
        return '';
      }
      let regex = new RegExp("^(?:[a-z]+:)?//", "i");
      return regex.test(this.relatedUrl);
    },
    imgBackground() {
      if (!this.imgUrl) {
        return '';
      }
      return `background-image:url('${this.imgUrl}');`;
    },
  }
};
</script>
