var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "d-flex flex-row" }, [
    _c("div", { staticClass: "flex-row-fluid" }, [
      _c(
        "div",
        { staticClass: "card card-custom position-relative" },
        [
          _vm.showLoader ? _c("LoaderPartial") : _vm._e(),
          _c("div", { staticClass: "card-header py-3" }, [
            _c(
              "div",
              { staticClass: "card-title align-items-start flex-column" },
              [
                _c(
                  "h3",
                  { staticClass: "card-label font-weight-bolder text-dark" },
                  [_vm._v(" " + _vm._s(_vm.notification.title) + " ")]
                ),
                _c(
                  "span",
                  {
                    staticClass: "text-muted font-weight-bold font-size-sm mt-1"
                  },
                  [
                    _vm._v(
                      _vm._s(_vm.$t("NOTIFICATIONS.FORMS.NEW_DESCRIPTION"))
                    )
                  ]
                )
              ]
            )
          ]),
          _c(
            "div",
            {
              staticClass:
                "row justify-content-center my-10 px-8 my-lg-15 px-lg-10"
            },
            [
              _c("div", { staticClass: "col-xl-12 col-xxl-11" }, [
                _c("form", { staticClass: "form" }, [
                  _c(
                    "div",
                    { staticClass: "card-body" },
                    [
                      _c("h4", [
                        _vm._v(
                          _vm._s(_vm.$t("NOTIFICATIONS.FORMS.SECTIONS.CONTENT"))
                        )
                      ]),
                      _c("br"),
                      _c("div", { staticClass: "form-group" }, [
                        _c("label", [
                          _vm._v(_vm._s(_vm.$t("NOTIFICATIONS.FORMS.ICON")))
                        ]),
                        _c("div", [
                          _c(
                            "div",
                            {
                              staticClass: "image-input image-input-outline",
                              attrs: { id: "kt_profile_avatar" }
                            },
                            [
                              _vm.current_photo
                                ? _c("div", {
                                    staticClass: "image-input-wrapper",
                                    style: {
                                      backgroundImage:
                                        "url('" + _vm.photo + "')",
                                      backgroundPosition: "center center"
                                    }
                                  })
                                : _c(
                                    "div",
                                    {
                                      staticClass:
                                        "image-input-wrapper d-flex justify-content-center"
                                    },
                                    [
                                      _c("i", {
                                        staticClass:
                                          "icon-6x flaticon-bell text-primary"
                                      })
                                    ]
                                  ),
                              !_vm.notification.id
                                ? _c(
                                    "label",
                                    {
                                      staticClass:
                                        "btn btn-xs btn-icon btn-circle btn-white btn-hover-text-primary btn-shadow",
                                      attrs: {
                                        "data-action": "change",
                                        "data-toggle": "tooltip",
                                        title: "",
                                        "data-original-title": "Change avatar"
                                      }
                                    },
                                    [
                                      _c("i", {
                                        staticClass:
                                          "fa fa-pen icon-sm text-muted"
                                      }),
                                      _c("input", {
                                        attrs: {
                                          type: "file",
                                          name: "profile_avatar",
                                          accept: ".png, .jpg, .jpeg"
                                        },
                                        on: {
                                          change: function($event) {
                                            return _vm.onFileChange($event)
                                          }
                                        }
                                      }),
                                      _c("input", {
                                        attrs: {
                                          type: "hidden",
                                          name: "profile_avatar_remove"
                                        }
                                      })
                                    ]
                                  )
                                : _vm._e()
                            ]
                          ),
                          _c("span", { staticClass: "form-text text-muted" }, [
                            _vm._v("Tipos permitidos: png, jpg, jpeg.")
                          ])
                        ])
                      ]),
                      !!_vm.notification.id
                        ? _c("div", { staticClass: "form-group" }, [
                            _c("label", [_vm._v("ID")]),
                            _c("br"),
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.notification.id,
                                  expression: "notification.id"
                                }
                              ],
                              ref: "id",
                              staticClass:
                                "form-control form-control-lg form-control-solid",
                              attrs: { type: "text", name: "id", disabled: "" },
                              domProps: { value: _vm.notification.id },
                              on: {
                                input: function($event) {
                                  if ($event.target.composing) {
                                    return
                                  }
                                  _vm.$set(
                                    _vm.notification,
                                    "id",
                                    $event.target.value
                                  )
                                }
                              }
                            })
                          ])
                        : _vm._e(),
                      _c("div", { staticClass: "form-group" }, [
                        _c("label", [
                          _vm._v(_vm._s(_vm.$t("NOTIFICATIONS.FORMS.TITLE")))
                        ]),
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.notification.title,
                              expression: "notification.title"
                            }
                          ],
                          ref: "title",
                          staticClass:
                            "form-control form-control-lg form-control-solid",
                          attrs: {
                            type: "text",
                            disabled: _vm.notification.id ? true : false
                          },
                          domProps: { value: _vm.notification.title },
                          on: {
                            input: function($event) {
                              if ($event.target.composing) {
                                return
                              }
                              _vm.$set(
                                _vm.notification,
                                "title",
                                $event.target.value
                              )
                            }
                          }
                        }),
                        !_vm.$v.notification.title.required
                          ? _c("div", { staticClass: "error text-primary" }, [
                              _vm._v(" " + _vm._s(_vm.$t("MSG.REQUIRED")) + " ")
                            ])
                          : _vm._e(),
                        !_vm.$v.notification.title.minLength
                          ? _c("div", { staticClass: "error text-primary" }, [
                              _vm._v(
                                " Son requeridos por lo menos 4 caracteres. "
                              )
                            ])
                          : _vm._e(),
                        !_vm.$v.notification.title.maxLength
                          ? _c("div", { staticClass: "error text-primary" }, [
                              _vm._v(
                                " Máximo " +
                                  _vm._s(
                                    _vm.$v.notification.title.$params.maxLength
                                      .max
                                  ) +
                                  " caracteres. "
                              )
                            ])
                          : _vm._e()
                      ]),
                      _c("div", { staticClass: "form-group" }, [
                        _c("label", [
                          _vm._v(_vm._s(_vm.$t("NOTIFICATIONS.FORMS.BODY")))
                        ]),
                        _c("textarea", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.notification.description,
                              expression: "notification.description"
                            }
                          ],
                          ref: "description",
                          staticClass:
                            "form-control form-control-lg form-control-solid",
                          attrs: {
                            type: "text",
                            disabled: _vm.notification.id ? true : false
                          },
                          domProps: { value: _vm.notification.description },
                          on: {
                            input: function($event) {
                              if ($event.target.composing) {
                                return
                              }
                              _vm.$set(
                                _vm.notification,
                                "description",
                                $event.target.value
                              )
                            }
                          }
                        }),
                        !_vm.$v.notification.description.minLength
                          ? _c("div", { staticClass: "error text-primary" }, [
                              _vm._v(
                                " Son requeridos por lo menos 5 caracteres. "
                              )
                            ])
                          : _vm._e(),
                        !_vm.$v.notification.description.maxLength
                          ? _c("div", { staticClass: "error text-primary" }, [
                              _vm._v(
                                " Máximo " +
                                  _vm._s(
                                    _vm.$v.notification.description.$params
                                      .maxLength.max
                                  ) +
                                  " caracteres. "
                              )
                            ])
                          : _vm._e()
                      ]),
                      _c("div", { staticClass: "form-group" }, [
                        _c("label", [
                          _vm._v(
                            _vm._s(_vm.$t("NOTIFICATIONS.FORMS.URL.LABEL"))
                          )
                        ]),
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.notification.url,
                              expression: "notification.url"
                            }
                          ],
                          ref: "url",
                          staticClass:
                            "form-control form-control-lg form-control-solid",
                          attrs: {
                            type: "text",
                            placeholder: "Redirección al dar click",
                            disabled: _vm.notification.id ? true : false
                          },
                          domProps: { value: _vm.notification.url },
                          on: {
                            input: function($event) {
                              if ($event.target.composing) {
                                return
                              }
                              _vm.$set(
                                _vm.notification,
                                "url",
                                $event.target.value
                              )
                            }
                          }
                        }),
                        _c("span", { staticClass: "form-text text-muted" }, [
                          _vm._v(
                            " " +
                              _vm._s(_vm.$t("NOTIFICATIONS.FORMS.URL.HELP")) +
                              " "
                          )
                        ]),
                        !_vm.$v.notification.url.urlOrRel
                          ? _c("div", { staticClass: "error text-primary" }, [
                              _vm._v(" Esta url no es válida. ")
                            ])
                          : _vm._e()
                      ]),
                      _c("br"),
                      _c("br"),
                      _c("h4", [
                        _vm._v(
                          _vm._s(
                            _vm.$t("NOTIFICATIONS.FORMS.SECTIONS.SHOW_FROM")
                          )
                        )
                      ]),
                      _c("br"),
                      _c(
                        "div",
                        { staticClass: "form-group" },
                        [
                          _c(
                            "b-form-checkbox",
                            {
                              attrs: {
                                name: "check-button",
                                size: "lg",
                                switch: ""
                              },
                              model: {
                                value: _vm.showFrom.now,
                                callback: function($$v) {
                                  _vm.$set(_vm.showFrom, "now", $$v)
                                },
                                expression: "showFrom.now"
                              }
                            },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.showFrom.now
                                      ? _vm.$t("NOTIFICATIONS.FORMS.NOW")
                                      : _vm.$t("NOTIFICATIONS.FORMS.FUTURE")
                                  ) +
                                  " "
                              )
                            ]
                          ),
                          _c(
                            "b-collapse",
                            {
                              attrs: { id: "collapse-datetime-picker" },
                              model: {
                                value: _vm.hideDateTimePicker,
                                callback: function($$v) {
                                  _vm.hideDateTimePicker = $$v
                                },
                                expression: "hideDateTimePicker"
                              }
                            },
                            [
                              _c(
                                "div",
                                { staticClass: "form-inline" },
                                [
                                  _c("b-form-datepicker", {
                                    staticClass:
                                      "form-control form-control-solid form-control-lg",
                                    attrs: {
                                      id: "datepicker-showfrom-date",
                                      "date-format-options": {
                                        year: "numeric",
                                        month: "numeric",
                                        day: "numeric"
                                      },
                                      disabled: _vm.notification.id
                                        ? true
                                        : false,
                                      locale: "es",
                                      "value-as-date": true
                                    },
                                    model: {
                                      value: _vm.showFrom.date,
                                      callback: function($$v) {
                                        _vm.$set(_vm.showFrom, "date", $$v)
                                      },
                                      expression: "showFrom.date"
                                    }
                                  }),
                                  _c("b-form-timepicker", {
                                    staticClass:
                                      "form-control form-control-solid form-control-lg",
                                    attrs: { locale: "es" },
                                    model: {
                                      value: _vm.showFrom.time,
                                      callback: function($$v) {
                                        _vm.$set(_vm.showFrom, "time", $$v)
                                      },
                                      expression: "showFrom.time"
                                    }
                                  })
                                ],
                                1
                              ),
                              _c(
                                "div",
                                {
                                  directives: [
                                    {
                                      name: "show",
                                      rawName: "v-show",
                                      value:
                                        !_vm.$v.showFrom.date.required ||
                                        !_vm.$v.showFrom.time.required,
                                      expression:
                                        "!$v.showFrom.date.required || !$v.showFrom.time.required"
                                    }
                                  ],
                                  staticClass: "error text-primary"
                                },
                                [
                                  _vm._v(
                                    " " + _vm._s(_vm.$t("MSG.REQUIRED")) + " "
                                  )
                                ]
                              ),
                              _c(
                                "div",
                                {
                                  directives: [
                                    {
                                      name: "show",
                                      rawName: "v-show",
                                      value:
                                        _vm.$v.showFrom.date.required &&
                                        _vm.$v.showFrom.time.required &&
                                        _vm.showFromIsPast,
                                      expression:
                                        "$v.showFrom.date.required && $v.showFrom.time.required && showFromIsPast"
                                    }
                                  ],
                                  staticClass: "error text-warning"
                                },
                                [
                                  _vm._v(
                                    " Esta notificación no será nueva para usuarios que hayan visto sus notificaciones después de esta fecha/hora. "
                                  )
                                ]
                              )
                            ]
                          )
                        ],
                        1
                      ),
                      !!_vm.notification.id
                        ? _c("div", { staticClass: "form-group" }, [
                            _c("label", [
                              _vm._v(
                                _vm._s(_vm.$t("NOTIFICATIONS.FORMS.CREATED_AT"))
                              )
                            ]),
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.notification.createdAt,
                                  expression: "notification.createdAt"
                                }
                              ],
                              ref: "date",
                              staticClass:
                                "form-control form-control-lg form-control-solid",
                              attrs: { type: "text", disabled: "" },
                              domProps: { value: _vm.notification.createdAt },
                              on: {
                                input: function($event) {
                                  if ($event.target.composing) {
                                    return
                                  }
                                  _vm.$set(
                                    _vm.notification,
                                    "createdAt",
                                    $event.target.value
                                  )
                                }
                              }
                            })
                          ])
                        : _vm._e(),
                      _c("br"),
                      _c("br"),
                      _c("h4", [
                        _vm._v(
                          _vm._s(
                            _vm.$t("NOTIFICATIONS.FORMS.SECTIONS.SEGMENTATION")
                          )
                        )
                      ]),
                      _c("br"),
                      _c(
                        "b-form-checkbox",
                        {
                          attrs: {
                            name: "check-button",
                            size: "lg",
                            switch: ""
                          },
                          model: {
                            value: _vm.notification.showToAll,
                            callback: function($$v) {
                              _vm.$set(_vm.notification, "showToAll", $$v)
                            },
                            expression: "notification.showToAll"
                          }
                        },
                        [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.notification.showToAll
                                  ? _vm.$t(
                                      "NOTIFICATIONS.FORMS.SHOW_TO_ALL.ALL"
                                    )
                                  : _vm.$t(
                                      "NOTIFICATIONS.FORMS.SHOW_TO_ALL.SELECT"
                                    )
                              ) +
                              " "
                          )
                        ]
                      ),
                      _c(
                        "b-collapse",
                        {
                          attrs: { id: "collapse-select-client" },
                          model: {
                            value: _vm.hideSelectClients,
                            callback: function($$v) {
                              _vm.hideSelectClients = $$v
                            },
                            expression: "hideSelectClients"
                          }
                        },
                        [
                          _c(
                            "div",
                            { staticClass: "form-group" },
                            [
                              !_vm.notification.id
                                ? _c("label", [
                                    _vm._v(
                                      _vm._s(
                                        _vm.$t(
                                          "NOTIFICATIONS.FORMS.TARGET.CLIENTS_SEARCH"
                                        )
                                      )
                                    )
                                  ])
                                : _vm._e(),
                              !_vm.notification.id
                                ? _c(
                                    "div",
                                    {
                                      staticClass: "row",
                                      staticStyle: {
                                        "max-width": "calc(100% + 13px)"
                                      }
                                    },
                                    [
                                      _c("vue-bootstrap-typeahead", {
                                        staticClass: "col-9",
                                        attrs: {
                                          id: "tenantSearchBox",
                                          data: _vm.tenants.filter(function(
                                            tenant
                                          ) {
                                            return !_vm.coreTenanciesTargets.some(
                                              function(t) {
                                                return t.id == tenant.id
                                              }
                                            )
                                          }),
                                          serializer: function(item) {
                                            return item.name
                                          }
                                        },
                                        on: {
                                          hit: function($event) {
                                            _vm.tenantSelected = $event
                                          },
                                          keyup: function($event) {
                                            if (
                                              !$event.type.indexOf("key") &&
                                              _vm._k(
                                                $event.keyCode,
                                                "enter",
                                                13,
                                                $event.key,
                                                "Enter"
                                              )
                                            ) {
                                              return null
                                            }
                                            return _vm.addClient($event)
                                          }
                                        },
                                        model: {
                                          value: _vm.tenantSearch,
                                          callback: function($$v) {
                                            _vm.tenantSearch = $$v
                                          },
                                          expression: "tenantSearch"
                                        }
                                      }),
                                      _c(
                                        "button",
                                        {
                                          staticClass:
                                            "btn btn-primary col-3 form-control-solid form-control-md",
                                          attrs: {
                                            disabled: !_vm.tenantSelected
                                              ? true
                                              : false
                                          },
                                          on: {
                                            click: function($event) {
                                              return _vm.addClient($event)
                                            }
                                          }
                                        },
                                        [
                                          _vm._v(
                                            " " +
                                              _vm._s(_vm.$t("ACTION.ADD")) +
                                              " "
                                          )
                                        ]
                                      )
                                    ],
                                    1
                                  )
                                : _vm._e(),
                              !_vm.notification.id
                                ? _c(
                                    "span",
                                    { staticClass: "form-text text-muted" },
                                    [
                                      _vm._v(
                                        "Escribe al menos 2 letras del nombre del cliente"
                                      )
                                    ]
                                  )
                                : _vm._e(),
                              _c("br"),
                              _c("label", [
                                _vm._v(
                                  _vm._s(
                                    _vm.$t(
                                      "NOTIFICATIONS.FORMS.TARGET.SELECTED_CLIENTS"
                                    )
                                  )
                                )
                              ]),
                              _c(
                                "b-list-group",
                                _vm._l(_vm.coreTenanciesTargets, function(
                                  tenant,
                                  i
                                ) {
                                  return _c(
                                    "b-list-group-item",
                                    {
                                      key: i,
                                      staticClass:
                                        "d-flex justify-content-between align-items-center"
                                    },
                                    [
                                      _vm._v(" " + _vm._s(tenant.name) + " "),
                                      !_vm.notification.id
                                        ? _c(
                                            "b-badge",
                                            {
                                              staticClass: "m-0",
                                              staticStyle: {
                                                cursor: "pointer"
                                              },
                                              attrs: { variant: "primary" },
                                              on: {
                                                click: function($event) {
                                                  return _vm.coreTenanciesTargets.splice(
                                                    i,
                                                    1
                                                  )
                                                }
                                              }
                                            },
                                            [_vm._v("✖︎")]
                                          )
                                        : _vm._e()
                                    ],
                                    1
                                  )
                                }),
                                1
                              ),
                              _c(
                                "span",
                                { staticClass: "form-text text-muted" },
                                [
                                  _vm._v(
                                    _vm._s(_vm.coreTenanciesTargets.length) +
                                      " Cliente" +
                                      _vm._s(
                                        _vm.coreTenanciesTargets.length != 1
                                          ? "s"
                                          : ""
                                      ) +
                                      " a notificar"
                                  )
                                ]
                              ),
                              _c(
                                "div",
                                {
                                  directives: [
                                    {
                                      name: "show",
                                      rawName: "v-show",
                                      value: !_vm.$v.coreTenanciesTargets
                                        .required,
                                      expression:
                                        "!$v.coreTenanciesTargets.required"
                                    }
                                  ],
                                  staticClass: "error text-primary form-text"
                                },
                                [_vm._v(" Debes agregar al menos un cliente. ")]
                              )
                            ],
                            1
                          )
                        ]
                      ),
                      _c("br"),
                      _c("br"),
                      _c("h4", [
                        _vm._v(
                          _vm._s(_vm.$t("NOTIFICATIONS.FORMS.SECTIONS.PREVIEW"))
                        )
                      ]),
                      _c("br"),
                      _c(
                        "div",
                        {
                          staticClass: "form-group",
                          staticStyle: { "max-width": "375px" }
                        },
                        [
                          _c(
                            "div",
                            {
                              staticClass:
                                "navi navi-icon-circle navi-spacer-x-0"
                            },
                            [
                              _c("NotificationsItemPreview", {
                                attrs: {
                                  title: _vm.notification.title,
                                  description: _vm.notification.description,
                                  relatedUrl: _vm.notification.url
                                    ? _vm.notification.url
                                    : null,
                                  seen: false,
                                  imgUrl: _vm.current_photo
                                }
                              })
                            ],
                            1
                          )
                        ]
                      )
                    ],
                    1
                  )
                ])
              ])
            ]
          ),
          _c(
            "div",
            {
              staticClass:
                "d-flex justify-content-end border-top pt-5 pr-5 pb-5"
            },
            [
              _c("div", { staticClass: "card-toolbar" }, [
                !_vm.notification.id
                  ? _c(
                      "button",
                      {
                        ref: "kt_save_changes",
                        staticClass: "btn btn-success mr-2",
                        attrs: {
                          type: "reset",
                          disabled:
                            _vm.$v.notification.$invalid ||
                            _vm.$v.coreTenanciesTargets.$invalid ||
                            _vm.$v.showFrom.$invalid
                        },
                        on: {
                          click: function($event) {
                            return _vm.create()
                          }
                        }
                      },
                      [_vm._v(" " + _vm._s(_vm.$t("ACTION.CREATE")) + " ")]
                    )
                  : _vm._e(),
                _c(
                  "a",
                  {
                    staticClass: "btn btn-secondary",
                    attrs: { type: "reset", href: "#/notifications" }
                  },
                  [
                    _vm._v(
                      " " +
                        _vm._s(
                          !_vm.notification.id
                            ? _vm.$t("ACTION.CANCEL")
                            : _vm.$t("ACTION.BACK")
                        ) +
                        " "
                    )
                  ]
                )
              ])
            ]
          )
        ],
        1
      )
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }