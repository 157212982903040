<template>
  <!-- begin::Page loader -->
  <!-- NOTE: component parent must have position:relative -->
  <div class="section-loader section-loader-logo">
    <img v-if="logo != null" alt="Logo" :src="logo" width="100" />
    <div class="spinner" v-bind:class="spinnerClass || 'spinner-primary'"></div>
  </div>
  <!-- end::Page Loader -->
</template>

<script>
export default {
  name: "LoaderPartial",
  props: {
    logo: {
      default: null,
      type: String
    },
    spinnerClass: String
  }
};
</script>
