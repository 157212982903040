<template>
  <div class="d-flex flex-row">

    <!--begin::Content-->
    <div class="flex-row-fluid">
      <!--begin::Card-->
      <div class="card card-custom position-relative">
        <LoaderPartial v-if="showLoader"></LoaderPartial>
        <!--begin::Header-->
        <div class="card-header py-3">
          <div class="card-title align-items-start flex-column">
            <h3 class="card-label font-weight-bolder text-dark">
              {{ notification.title }}
            </h3>
            <span class="text-muted font-weight-bold font-size-sm mt-1"
              >{{ $t("NOTIFICATIONS.FORMS.NEW_DESCRIPTION") }}</span
            >
          </div>
        </div>
        <!--end::Header-->
        <!--begin::Form-->
        <div class="row justify-content-center my-10 px-8 my-lg-15 px-lg-10">
          <div class="col-xl-12 col-xxl-11">
            <form class="form">
              <!--begin::Body-->
              <div class="card-body">
                <!-- <div class="form-group">
                      <label>Nombre del notificatione</label>
                      <input
                        ref="notification"
                        type="text"
                        name="notification"
                        class="form-control form-control-lg form-control-solid"
                        placeholder="Nombre del notificatione"
                        v-model="notification.notification"
                      />
                </div> -->
                <h4>{{ $t("NOTIFICATIONS.FORMS.SECTIONS.CONTENT") }}</h4><br>
                <div class="form-group">
                  <label>{{ $t("NOTIFICATIONS.FORMS.ICON") }}</label>
                  <div>
                    <div
                      class="image-input image-input-outline"
                      id="kt_profile_avatar"
                    >
                      <div
                        class="image-input-wrapper"
                        :style="{
                          backgroundImage: `url('${photo}')`,
                          backgroundPosition: 'center center'
                        }"
                        v-if="current_photo"
                      ></div>
                      <div v-else class="image-input-wrapper d-flex justify-content-center">
                        <i class="icon-6x flaticon-bell text-primary" />
                      </div>
                      <label
                        class="btn btn-xs btn-icon btn-circle btn-white btn-hover-text-primary btn-shadow"
                        data-action="change"
                        data-toggle="tooltip"
                        title=""
                        data-original-title="Change avatar"
                        v-if="!notification.id"
                      >
                        <i class="fa fa-pen icon-sm text-muted"></i>
                        <input
                          type="file"
                          name="profile_avatar"
                          accept=".png, .jpg, .jpeg"
                          @change="onFileChange($event)"
                        />
                        <input type="hidden" name="profile_avatar_remove" />
                      </label>
                      <!-- <span
                        class="btn btn-xs btn-icon btn-circle btn-white btn-hover-text-primary btn-shadow"
                        data-action="remove"
                        data-toggle="tooltip"
                        title="Remove avatar"
                        @click="notification.image = null"
                      >
                        <i class="ki ki-bold-close icon-xs text-muted"></i>
                      </span> -->
                    </div>
                    <span class="form-text text-muted"
                      >Tipos permitidos: png, jpg, jpeg.</span
                    >
                  </div>
                </div>
                <!-- <div class="form-group row">
                  <label class="col-xl-3 col-lg-3 col-form-label text-right"
                    >Imagen</label
                  >
                  <div class="col-lg-9 col-xl-6">
                    <input
                      ref="image"
                      class="form-control form-control-lg form-control-solid"
                      type="text"
                      v-model="notification.image"
                    />
                  </div>
                </div> -->
                <div class="form-group" v-if="!!notification.id">
                  <label>ID</label><br>
                  <input 
                    ref="id"
                    type="text"
                    name="id"
                    class="form-control form-control-lg form-control-solid"
                    v-model="notification.id"
                    disabled
                  />
                  
                </div>
                <div
                  class="form-group"
                >
                  <label>{{ $t("NOTIFICATIONS.FORMS.TITLE") }}</label>
                  <input
                    ref="title"
                    class="form-control form-control-lg form-control-solid"
                    type="text"
                    v-model="notification.title"
                    :disabled="notification.id ? true : false"
                  />
                  <div class="error text-primary" v-if="!$v.notification.title.required">
                    {{ $t("MSG.REQUIRED") }}
                  </div>
                  <div class="error text-primary" v-if="!$v.notification.title.minLength">
                    Son requeridos por lo menos 4 caracteres.
                  </div>
                  <div class="error text-primary" v-if="!$v.notification.title.maxLength">
                    Máximo {{$v.notification.title.$params.maxLength.max}} caracteres.
                  </div>
                </div>

                <div class="form-group">
                  <label>{{ $t("NOTIFICATIONS.FORMS.BODY") }}</label>
                  <textarea
                    ref="description"
                    class="form-control form-control-lg form-control-solid"
                    type="text"
                    v-model="notification.description"
                    :disabled="notification.id ? true : false"
                  ></textarea>
                  <div
                    class="error text-primary"
                    v-if="!$v.notification.description.minLength"
                  >
                    Son requeridos por lo menos 5 caracteres.
                  </div>
                  <div class="error text-primary" v-if="!$v.notification.description.maxLength">
                    Máximo {{$v.notification.description.$params.maxLength.max}} caracteres.
                  </div>
                </div>
                <div class="form-group">
                  <label>{{ $t("NOTIFICATIONS.FORMS.URL.LABEL") }}</label>
                  <input
                    ref="url"
                    type="text"
                    class="form-control form-control-lg form-control-solid"
                    placeholder="Redirección al dar click"
                    v-model="notification.url"
                    :disabled="notification.id ? true : false"
                  />
                  <span class="form-text text-muted">
                    {{ $t("NOTIFICATIONS.FORMS.URL.HELP") }}
                  </span>
                  <div class="error text-primary" v-if="!$v.notification.url.urlOrRel">
                    Esta url no es válida.
                  </div>
                </div>
                <br><br>
                <h4>{{ $t("NOTIFICATIONS.FORMS.SECTIONS.SHOW_FROM") }}</h4><br>
                <div class="form-group">
                  <!-- <label>{{ $t("NOTIFICATIONS.FORMS.SHOW_FROM") }}</label> -->
                  <b-form-checkbox 
                    v-model="showFrom.now" 
                    name="check-button" 
                    size="lg"
                    switch>
                    {{ showFrom.now ? $t("NOTIFICATIONS.FORMS.NOW") : $t("NOTIFICATIONS.FORMS.FUTURE") }}
                  </b-form-checkbox>
                  <b-collapse id="collapse-datetime-picker" v-model="hideDateTimePicker">
                    <div class="form-inline">
                      <b-form-datepicker
                        id="datepicker-showfrom-date"
                        class="form-control form-control-solid form-control-lg"
                        :date-format-options="{
                          year: 'numeric',
                          month: 'numeric',
                          day: 'numeric'
                        }"
                        :disabled="notification.id ? true : false"
                        v-model="showFrom.date"
                        locale="es"
                        :value-as-date="true"
                      ></b-form-datepicker>
                      <b-form-timepicker 
                        v-model="showFrom.time" 
                        class="form-control form-control-solid form-control-lg"
                        locale="es"></b-form-timepicker>
                    </div>
                    <div
                      class="error text-primary"
                      v-show="!$v.showFrom.date.required || !$v.showFrom.time.required"
                    >
                      {{ $t("MSG.REQUIRED") }}
                    </div>
                    <div
                      class="error text-warning"
                      v-show="$v.showFrom.date.required && $v.showFrom.time.required && showFromIsPast"
                    >
                      Esta notificación no será nueva para usuarios que hayan visto sus notificaciones después de esta fecha/hora.
                    </div>
                  </b-collapse>
                </div>
                <div class="form-group" v-if="!!notification.id">
                  <label>{{ $t("NOTIFICATIONS.FORMS.CREATED_AT") }}</label>
                  <input
                    ref="date"
                    type="text"
                    class="form-control form-control-lg form-control-solid"
                    v-model="notification.createdAt"
                    disabled
                  />
                </div>
                <br><br>
                <h4>{{ $t("NOTIFICATIONS.FORMS.SECTIONS.SEGMENTATION") }}</h4><br>
                <b-form-checkbox 
                  v-model="notification.showToAll" 
                  name="check-button" 
                  size="lg"
                  switch>
                  {{ notification.showToAll ? $t("NOTIFICATIONS.FORMS.SHOW_TO_ALL.ALL") : $t("NOTIFICATIONS.FORMS.SHOW_TO_ALL.SELECT") }}
                </b-form-checkbox>
                <b-collapse id="collapse-select-client" v-model="hideSelectClients">
                  <div class="form-group">
                    <label v-if="!notification.id">{{ $t("NOTIFICATIONS.FORMS.TARGET.CLIENTS_SEARCH") }}</label>
                    <div class="row" style="max-width: calc(100% + 13px);" v-if="!notification.id">
                      <vue-bootstrap-typeahead
                        id="tenantSearchBox"
                        class="col-9"
                        v-model="tenantSearch"
                        :data="
                          tenants.filter(
                            tenant =>
                              !coreTenanciesTargets.some(t => t.id == tenant.id)
                          )
                        "
                        :serializer="item => item.name"
                        @hit="tenantSelected = $event"
                        @keyup.enter="addClient($event)"
                      />
                      <button
                        class="btn btn-primary col-3 form-control-solid form-control-md"
                        @click="addClient($event)"
                        :disabled="!tenantSelected ? true : false"
                      >
                        {{ $t("ACTION.ADD") }}
                      </button>
                    </div>
                    <span class="form-text text-muted" v-if="!notification.id"
                      >Escribe al menos 2 letras del nombre del cliente</span
                    >

                    <br />
                    <label>{{ $t("NOTIFICATIONS.FORMS.TARGET.SELECTED_CLIENTS") }}</label>
                    <b-list-group>
                      <b-list-group-item
                        v-for="(tenant, i) in coreTenanciesTargets"
                        :key="i"
                        class="d-flex justify-content-between align-items-center"
                      >
                        {{ tenant.name }}
                        <b-badge
                          variant="primary"
                          class="m-0"
                          style="cursor:pointer;"
                          v-if="!notification.id"
                          @click="coreTenanciesTargets.splice(i, 1)"
                          >✖︎</b-badge
                        >
                      </b-list-group-item>
                    </b-list-group>
                    <span class="form-text text-muted"
                      >{{ coreTenanciesTargets.length }} Cliente{{
                        coreTenanciesTargets.length != 1 ? "s" : ""
                      }}
                      a notificar</span
                    >
                    <div
                      class="error text-primary form-text"
                      v-show="!$v.coreTenanciesTargets.required"
                    >
                      Debes agregar al menos un cliente.
                    </div>
                  </div>
                </b-collapse>
                <br><br>
                <h4>{{ $t("NOTIFICATIONS.FORMS.SECTIONS.PREVIEW") }}</h4><br>
                <div class="form-group" style="max-width:375px">
                  <div class="navi navi-icon-circle navi-spacer-x-0">
                    <NotificationsItemPreview
                      :title="notification.title"
                      :description="notification.description"
                      :relatedUrl="notification.url ? notification.url : null"
                      :seen="false"
                      :imgUrl="current_photo"
                    ></NotificationsItemPreview>
                  </div>
                </div>
              </div>
              <!--end::Body-->
            </form>
          </div>
        </div>
        <!--end::Form-->
        <!--begin: Actions -->
        <div class="d-flex justify-content-end border-top pt-5 pr-5 pb-5">
          <div class="card-toolbar">
            <button
              type="reset"
              class="btn btn-success mr-2"
              @click="create()"
              ref="kt_save_changes"
              v-if="!notification.id"
              :disabled="$v.notification.$invalid || $v.coreTenanciesTargets.$invalid || $v.showFrom.$invalid"
            >
              {{ $t("ACTION.CREATE") }}
            </button>
            <a type="reset" class="btn btn-secondary" href="#/notifications">
              {{ !notification.id ? $t("ACTION.CANCEL") : $t("ACTION.BACK") }}
            </a>
          </div>
        </div>
        <!--end: Actions -->
      </div>
    </div>
    <!--end::Content-->
  </div>
</template>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import VueBootstrapTypeahead from "vue-bootstrap-typeahead";
import ApiService from "@/core/services/api.service";
import Swal from "sweetalert2";
import {
  required,
  minLength,
  maxLength,
  url,
  helpers,
  or,
  requiredIf
} from "vuelidate/lib/validators";
import LoaderPartial from "@/view/content/LoaderPartial.vue";
import NotificationsItemPreview from "@/view/pages/notifications/NotificationsItemPreview.vue";

// Custom Validations
const relativeUrl = helpers.regex("relativeUrl", /^\/#\//);
// const atLeastOneClient = (value) => value.length > 0;

export default {
  name: "notification",
  components: {
    VueBootstrapTypeahead,
    LoaderPartial,
    NotificationsItemPreview
  },
  data() {
    return {
      resource: "notifications",
      notification: {
        title: "",
        description: "",
        url: "",
        coreTenanciesTargets: [],
        showFrom: null,
        showToAll: true
      },
      showFrom: {
        date: null,
        time: null,
        now: true
      },
      coreTenanciesTargets: [],
      default_photo: "media/users/blank.png",
      current_photo: null,
      showLoader: true,
      tenants: [],
      tenantSearch: "",
      tenantSelected: null,
      visibilityOptions: [
        { item: "public", name: "Publico" },
        { item: "private", name: "Privado" },
        { item: "hidden", name: "Oculto" }
      ],
    };
  },
  validations: {
    notification: {
      title: {
        required,
        minLength: minLength(4),
        maxLength: maxLength(50)
      },
      description: {
        minLength: minLength(5),
        maxLength: maxLength(150)
      },
      url: {
        urlOrRel: or(url, relativeUrl)
      }
    },
    coreTenanciesTargets: {
      required: requiredIf(function() {
        if (!this.notification.showToAll) {
          return true;
        }
        return false;
      }),
      minLength: minLength(1)
    },
    showFrom:{
      date: {
        required: requiredIf(function() {
          if (!this.showFrom.now) {
            return true;
          }
          return false;
        })
      },
      time: {
        required: requiredIf(function() {
          if (!this.showFrom.now) {
            return true;
          }
          return false;
        })
      }
    },
    // editNotification: ["notification.title", "notification.description"]
  },
  async mounted() {
    if (this.$route.params.id) {
      console.log("notification existent");
      let getResource;
      try {
        getResource = await this.getResource(this.$route.params.id);
      } catch (e) {
        console.log(404);
        this.$router.push({ name: "404" });
      }
      
      this.notification = getResource;
      this.current_photo = this.notification.image;
      this.$store.dispatch(SET_BREADCRUMB, [{ title: "Notificaciones", route: "/notifications"}, {title: getResource.title}]);
    }else{
      this.$store.dispatch(SET_BREADCRUMB, [{ title: "Notificaciones", route: "/notifications"}, {title: "Nueva Notificación"}]);
    }
    this.showLoader = false;

    this.tenants = await this.getClients();
    this.coreTenanciesTargets = this.tenants.filter(tenant =>
      this.notification.coreTenanciesTargets?.some(tenantId => tenant.id == tenantId)
    );
  },
  methods: {
    addClient(event) {
      event.preventDefault();
      if (this.tenantSelected) {
        console.log(this.tenantSelected);
        this.coreTenanciesTargets.push(this.tenantSelected);
        this.tenantSelected = null;
        this.tenantSearch = null;
        console.log(this.tenantSelected, this.tenantSearch);
      }
    },
    async getResource(id) {
      let { data } = await ApiService.get(`${this.resource}`, id);
      //console.log(data);
      return data;
    },
    async getClients() {
      let { data } = await ApiService.get(`tenancies`);
      console.log({ ...data });
      return data;
    },
    async create() {
      console.log(this.notification);
      const submitButton = this.$refs["kt_save_changes"];
      submitButton.classList.add("spinner", "spinner-light", "spinner-right");
      submitButton.disabled = true;
      this.notification.showFrom = this.showFrom.now ? new Date() : this.addTimeStringToDate(this.showFrom.date, this.showFrom.time);
      if (!this.notification.showToAll) {
        this.notification.coreTenanciesTargets = this.coreTenanciesTargets.map(
          tenant => tenant.id
        );
      }

      // dummy delay
      setTimeout(async () => {
        // send update request
        try {
          let { data } = await ApiService.post(`${this.resource}`, this.notification);

          submitButton.classList.remove(
            "spinner",
            "spinner-light",
            "spinner-right"
          );
          submitButton.disabled = false;

          if (data.id) {
            this.notification = data;
            this.$router.push({ path: `/notifications/${data.id}` });
          }

          Swal.fire({
            title: "",
            text: "La notificación ha sido creada exitosamente!",
            icon: "success",
            confirmButtonClass: "btn btn-secondary"
          }).then(function() {
            window.location.href = "#/notifications";
          });

          submitButton.classList.remove(
            "spinner",
            "spinner-light",
            "spinner-right"
          );
          submitButton.disabled = false;
        } catch (e) {
          let message =
            "Por favor revisa que los datos ingresados sean correctos";

          // if (e.response.data) {
          //   message = e.response.data.error.message;
          // }

          Swal.fire({
            title: "La notificación no ha sido creada",
            text: message,
            icon: "error",
            confirmButtonClass: "btn btn-secondary"
          });
          submitButton.classList.remove(
            "spinner",
            "spinner-light",
            "spinner-right"
          );
          submitButton.disabled = false;
        }
      }, 500);
    },
    async save() {
      // set spinner to submit button
      const submitButton = this.$refs["kt_save_changes"];
      submitButton.classList.add("spinner", "spinner-light", "spinner-right");
      submitButton.disabled = true;

      // dummy delay
      setTimeout(async () => {
        // send update request
        try {
          let { id, ...currentNotification } = Object.entries(this.notification).reduce(
            (a, [k, v]) => (v ? ((a[k] = v), a) : a),
            {}
          );
          currentNotification.coreTenanciesTargets = this.coreTenanciesTargets.map(
            tenant => tenant.id
          );
          currentNotification.showFrom = this.showFrom.now ? new Date() : this.addTimeStringToDate(this.showFrom.date, this.showFrom.time);
          console.log(currentNotification);
          await ApiService.patch(`${this.resource}/${id}`, currentNotification);
          Swal.fire({
            title: "",
            text: "El usuario ha sido guardado exitosamente!",
            icon: "success",
            confirmButtonClass: "btn btn-secondary"
          }).then(function() {
            window.location.href = "#/notifications";
          });
          submitButton.classList.remove(
            "spinner",
            "spinner-light",
            "spinner-right"
          );
          submitButton.disabled = false;
        } catch (e) {
          console.log(e);
          Swal.fire({
            title: "El usuario no ha sido creado",
            text: "Por favor revisa que los datos ingresados sean correctos",
            icon: "error",
            confirmButtonClass: "btn btn-secondary"
          });
          submitButton.classList.remove(
            "spinner",
            "spinner-light",
            "spinner-right"
          );
          submitButton.disabled = false;
        }
      }, 500);
    },
    async onFileChange(e) {
      const file = e.target.files[0];

      if (!this.notification.image) this.notification.image = null;

      if (typeof FileReader === "function") {
        const reader = new FileReader();

        reader.onload = event => {
          this.current_photo = event.target.result;
        };

        reader.readAsDataURL(file);
      } else {
        alert("Sorry, FileReader API not supported");
      }

      let formData = new FormData();

      formData.append("image", file);

      let { data } = await ApiService.post(`file-upload`, formData, {
        headers: { "Content-Type": "multipart/form-data" }
      });

      setTimeout(() => {
        this.notification.image = encodeURI(data.s3Files[0].completePath);
        console.log(data.s3Files[0].completePath, this.notification.image);
      }, 500);
    },
    addTimeStringToDate(date, timeString) {
      let timeSplit = timeString.split(':');
      let timeSeconds = (+timeSplit[0]) * 60 * 60 + (+timeSplit[1]) * 60 + (+timeSplit[2]); 
      let dt = new Date(date.getTime() + timeSeconds*1000);
      // console.log("NEW DATE:");
      // console.log(dt);
      return dt;
    }
  },
  computed: {
    photo() {
      return this.current_photo == null
        ? this.default_photo
        : this.current_photo;
    },
    hideDateTimePicker: {
      get(){
        return !this.showFrom.now;
      },
      set(){
        return null
      } 
    },
    hideSelectClients: {
      get(){
        return !this.notification.showToAll;
      },
      set(){
        return null
      } 
    },
    showFromIsPast() {
      if (this.showFrom.time && this.showFrom.date) {
        let now = new Date();
        if(now > this.addTimeStringToDate(this.showFrom.date, this.showFrom.time)) {
          return true;
        }
        return false;
      }
      return false;
    }
  }
  
};
</script>
